import React, { useState } from 'react'
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { storage, db } from '../../firebaseConfig';
import './admin.css'
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, LinearProgress, CircularProgress } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
// import { CloseTwoTone } from '@mui/icons-material';


const EventForm = () => {
  const navigate = useNavigate()
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [previewImage, setPreviewImage] = useState("");
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    eventTitle: "",
    description: "",
    startDate: "",
    endDate: "",
    time: "",
    venue: "",
    postedBy: "PCC Belts-Ville",
    action: "event"
  })

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };
  const removeImage = () => {
    setPreviewImage(null);
  };
  const onFileChangeHandler = (e) => {
    setImage(e.target.files[0]);
    if (e.target.files.length !== 0) {
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handlePost = (e) => {
    e.preventDefault();
    const {
      eventTitle,
      description,
      startDate,
      endDate,
      time,
      postedBy,
      action,
      venue
    } = formData

    if (!image) {
      setError("upload error");
    } else {
      setError("");
      setLoading(true)
      const storageRef = ref(storage, `/galleryAndEventsMedia/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(percent);
        },
        (err) => {
          const errorMessage = err.message;
          setError(errorMessage);
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            addDoc(collection(db, `/posts`), {
              timestamp: serverTimestamp(),
              imageUrl: url,
              postedBy: postedBy,
              eventTitle: eventTitle,
              description: description,
              startDate: startDate,
              endDate: endDate,
              time: time,
              venue: venue,
              action: action

            })
            setProgress(0);
            setPreviewImage(null);
            setImage(null);
            setLoading(false);
            navigate('/')
          });

        }
      )
    }
  }


    return (
      <>
        <section className='page-content'>
          <div className="main">
            <div className="main__container">
              <div className="main__create">
                <form className="main__form">
                  <div>
                    <label className="login__input-upload">Upload Image:</label>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        multiple
                        onChange={onFileChangeHandler}
                      />
                      <CameraAltIcon size={20} color="inherit" />
                    </IconButton>
                  </div>
                  <div className="preview__container">
                    {previewImage && (
                          <div className="my-2">
                            <div className="preview__imageContainer">
                              <img
                                className="previewImage"
                                src={previewImage}
                                alt=""
                                onClick={() => removeImage()}
                              />
                            </div>
                          </div>
                        )
                    }
                  </div>
                  {previewImage && (
                    <Box sx={{ width: "100%", margin: "10px 0px" }}>
                      <LinearProgress
                        variant="determinate"
                        value={progress}
                      />
                    </Box>
                  )
                  }
                  <div className="main__input">
                    <label>Title:</label>
                    <input placeholder=''
                      type="text" name="eventTitle" value={formData.eventTitle} onChange={handleInputChange} required
                    />

                  </div>
                
                        <div className="main__input">
                          <label>Start Date:</label>
                          <input
                            type="date" name="startDate" value={formData.startDate} onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="main__input">
                          <label>End Date:</label>
                          <input
                            type="date" name="endDate" value={formData.endDate} onChange={handleInputChange}
                          />
                        </div>
                        <div className="main__input">
                          <label>Time:</label>
                          <input
                            type="text" name="time" value={formData.time} onChange={handleInputChange} required
                          />
                        </div>
                        <div className="main__input">
                          <label>Venue:</label>
                          <input
                            type="text" name="venue" value={formData.venue} onChange={handleInputChange} required
                          />
                        </div>
                  <div className="main__input">
                    <label>Description:</label>
                    <textarea placeholder='Event description' cols="35" rows="3"
                      type="text" name="description" value={formData.description} onChange={handleInputChange} required
                    >

                    </textarea>
                  </div>
                  <div className="login__button">
                    {
                      formData.eventTitle && (
                        <button type="button" onClick={handlePost}>POST</button>
                      )
                    }
                  </div>
                  {
                    loading && (
                      <div className="loader">
                        <CircularProgress color="inherit" />
                      </div>
                    )
                  }
                  {
                    error && (
                      <span className="submit__error">{error}</span>
                    )
                  }
                </form>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

  export default EventForm