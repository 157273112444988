import React from 'react'
import './gallery.css'
//import { galleryImages } from '../../utils/dummyData'
import { GalleryCard, Pagination } from '../../components'
//pccbeltsville.org


const Gallery = () => {
  const gallery = JSON.parse(localStorage.getItem("gallery"))
  return (
    <>
      <section className="page-header page-header--gallery">
        <div className="row page-header__content">
          <div className="column">
            <h1>Gallery</h1>
          </div>
        </div>
      </section>
      <section className="page-content">
        <div className='gallery'>
          {
            gallery?.length === 0 && (
              <div className="testimonies__fallback">
                <h3>There are no pictures in the gallery at the moment</h3>
              </div>
            )
          }
          <div className="gallery__items">
            {
              gallery?.map(({ post, id }) => (
                <GalleryCard item={post} id={id} key={id} />
              ))
            }
          </div>
        </div>
        <Pagination events={gallery} />
      </section>
    </>

  )
}

export default Gallery