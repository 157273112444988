import { NavLink } from "react-router-dom";
import { EventCard, Socials, TestimonyCard } from "../../components";

export default function Home() {
    const events = JSON.parse(localStorage.getItem("events"))?.slice(0, 4)
    const testimonies = JSON.parse(localStorage.getItem("testimonies"))?.slice(0, 2)
    return (
        <>

            <section className="s-hero" data-parallax="scroll" data-image-src="./images/pcc2.jpeg"
                data-natural-width="3000" data-natural-height="2000" data-position-y="center">
                <div className="hero-left-bar"></div>

                <div className="row hero-content">

                    <div className="column large-full hero-content__text">
                        {/* <h1 >
                            We Exist To <br />
                            Honor God And <br />
                            Make Disciples
                        </h1> */}
                        <h1>
                            “The church reforming,<br />
                            and always being reformed, <br />
                            according to the Word of God.”
                        </h1>
                        <div className="hero-content__buttons">
                            <NavLink to="/events" className="btn btn--stroke">Upcoming Events</NavLink>
                            <NavLink to="/share-testimony" className="btn btn--stroke">Share your Story</NavLink>
                        </div>
                    </div>

                </div>

                <ul className="hero-social">
                    <li className="hero-social__title">Follow Us</li>
                    <li>
                        <NavLink to='facebook' target="_blank">Facebook</NavLink>
                    </li>
                    <li>
                        <NavLink to="#0" title="">YouTube</NavLink>
                    </li>
                    <li>
                        <NavLink to="#0" title="">Instagram</NavLink>
                    </li>
                </ul>
                <div className="hero-scroll">
                    <NavLink to="#about" className="scroll-link smoothscroll">
                        Scroll For More
                    </NavLink>
                </div>
            </section>


            <section id="about" className="s-about">

                <div className="row row-y-center about-content">

                    <div className="column large-half medium-full">
                        <h3 className="subhead">Welcome to PCC Beltsville</h3>
                        <p className="lead">
                            Welcome to PCC Beltsville Church, a place of faith and fellowship. Join us in worship and community service as we grow together in love and grace. Experience the warmth of our congregation and the strength of God’s word in our shared journey.
                        </p>
                        <NavLink to="/about" className="btn btn--primary btn--about">More About us</NavLink>
                    </div>

                    <div className="column large-half medium-full">
                        <ul className="about-sched">
                            <li>
                                <h4>Main Church Service</h4>
                                <p>
                                    Sundays: 10:45 AM - 1:00 PM  <br />
                                    9830 Mallard Dr, MD 20708<br />
                                    Laurel Maryland USA
                                </p>
                            </li>
                            <li>
                                <h4>Kids Church</h4>
                                <p>
                                    Sundays: 10:45 AM - 1:00 PM  <br />
                                    9830 Mallard Dr, MD 20708 <br />
                                    Laurel Maryland USA
                                </p>
                            </li>
                            {/* <li>
                                <h4>Prayer Meeting</h4>
                                <p>
                                    Saturday - 7:00 PM <br />
                                    1600 Amphitheatre Parkway, Mt. View, CA, 94043
                                </p>
                            </li> */}
                        </ul>             </div>

                </div>
            </section>
            <section className="series">
                <section className="s-events">
                    <div className="row events-header">
                        <div className="column">
                            <h2 className="subhead">Inspiring Testimonies.</h2>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="row block-large-1-2 block-900-full events-list">

                        {
                            testimonies?.map(({ post, id }) => (
                                <TestimonyCard item={post} id={id} key={id} />
                            ))
                        }

                        {
                            testimonies?.length === 0 && (
                                <div className="testimonies__fallback">
                                    <h3>There are no testimonies at the moment</h3>
                                </div>
                            )
                        }
                    </div>
                    <NavLink to="/testimonies" className="btn btn--primary btn--about">More Stories</NavLink>
                </section>
                <section className="s-events">
                    <div className="row events-header">
                        <div className="column">
                            <h2 className="subhead">Upcoming Events.</h2>
                        </div>
                    </div>
                    <br />
                    {/* row wide block-large-1-2 block-900-full */}
                    <div className="column wide block-large-1-2 block-900-full events-list">
                        {
                            events?.length === 0 && (
                                <div className="testimonies__fallback">
                                    <h3>There are no events at the moment</h3>
                                </div>
                            )
                        }
                        {
                            events?.map(({ post, id }) => (
                                <EventCard item={post} id={id} key={id} />
                            ))
                        }
                    </div>
                    <br />
                    <br />
                </section>
            </section>

            <section className="s-series">

                <div className="series-img" style={{ backgroundImage: `url(require('images/series-2000.jpg'))` }}></div>

                <div className="row row-y-center series-content">

                    <div className="column large-half medium-full">
                        <h3 className="subhead">Current Series</h3>
                        <h2>Shape Your Life with the Words of Life.</h2>
                        <p>
                            Community: Process God’s Word together, encourage one another, and stay accountable.
                            Reflection: Amid daily tasks, turn to Scripture—pray, meditate, align with God’s revealed will.
                            Obedience: Apply biblical truths in decisions and relationships. <br />
                            Love: Reflect God’s glory through His Word.
                        </p>
                    </div>
                    <div className="column large-half medium-full">
                        <div className="series-content__buttons">
                            <NavLink to="/gallery" className="btn btn--large h-full-width">Gallery</NavLink>
                            <NavLink to="/events" className="btn btn--large h-full-width">Events</NavLink>
                        </div>

                        <div className="series-content__subscribe">
                            <p>
                                Never missed a message. Subscribe to our YouTube and Podcast channels.
                            </p>

                            <ul className="series-content__subscribe-links">
                                <li className="ss-apple-podcast"><NavLink to="/">Apple Podcast</NavLink></li>
                                <li className="ss-spotify"><NavLink to="https://www.tiktok.com/@cyf_beltsville?_t=8mwtec3w8WV&_r=1">Tiktok</NavLink></li>
                                <li className="ss-soundcloud"><NavLink to="/">SoundCloud</NavLink></li>
                                <li className="ss-youtube"><NavLink to="https://youtube.com/@cyfbeltsville?si=op-Wds38-YVshtZ7" target="_blank">Youtube</NavLink></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </section>
            <Socials />
        </>
    )
}
