import React, { useEffect } from 'react'
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from '../../firebaseConfig';
import './testimonies.css'
import { Pagination, TestimonyCard } from '../../components';
import { Link } from 'react-router-dom'

const Testimonies = () => {
  const testimonies = JSON.parse(localStorage.getItem("testimonies"))

  useEffect(() => {
    const data = query(
      collection(db, "testimonies"),
      orderBy("timestamp", "desc")
    );
    const getData = async () => {
      await onSnapshot(data, (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          id: doc?.id,
          post: doc?.data(),
        }))

        localStorage.setItem("testimonies", JSON.stringify(newData))

      })
    }
    getData()
  }, [])

  return (
    <>
      <section className="page-header page-header--testimony">
        <div className="row page-header__content">
          <div className="column">
            <h1>Read stories of Transformation</h1>
          </div>
        </div>
      </section>
      <section className="page-content">
        <div className='testimonies'>
          <div className="testimony__buttons">
            <div className="testimony__button">
              <Link to='/events' className="footer__current-seriesLink">
                CHECKOUT EVENTS
              </Link>
            </div>
            <div className="testimony__button">
              <Link to='/share-testimony' className="footer__current-seriesLink">
                SHARE YOUR STORY
              </Link>
            </div>
          </div>
          <div className="testimonies__header">
            <p>Sharing your transformation story is powerful. It inspires others, revealing God’s grace and faithfulness. Describe your life before encountering Christ, the moment of change, and how it impacted you. Be authentic, emphasizing His work. Your story can ignite hope and draw hearts to Him. 🙏✨</p>
          </div>
          <div className="">
            {
              testimonies?.length === 0 && (
                <div className="testimonies__fallback">
                  <h3>There are no Inspiring stories at the moment</h3>
                </div>
              )
            }
          </div>
          <div className="gallery__items">
            {
              testimonies?.map(({ post, id }) => (
                <TestimonyCard item={post} id={id} key={id} />
              ))
            }
          </div>
        </div>
        <Pagination events={testimonies} />
      </section>
    </>
  )
}

export default Testimonies
