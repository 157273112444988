import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { logoNew } from "../../../assets";

export default function Header() {
  const [isClicked, setIsClicked] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleMenu = () => {
    setIsClicked(!isClicked);
  };

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsClicked(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="s-header">
      <div className="header-logo">
        <NavLink className="" to="/">
          <div className="header__logo">
            <img src={logoNew} alt="header" />
          </div>
        </NavLink>
      </div>
      <nav
        className={`header-nav-wrap ${isMobile ? "mobile" : ""}`}
        style={{ display: isMobile && !isClicked ? "none" : "block" }}
      >
        <ul className="header-nav">
          <li>
            <NavLink className="current" to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" title="About">
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/events" title="Services">
              Events
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" title="Contact us">
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink to="/gallery" title="Gallery">
              Gallery
            </NavLink>
          </li>
        </ul>
      </nav>
      <NavLink
        className={`header-menu-toggle ${isClicked ? "is-clicked" : ""}`}
        onClick={toggleMenu}
        href="#0"
      >
        <span>Menu</span>
      </NavLink>
    </header>
  );
}
