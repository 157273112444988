import { NavLink } from 'react-router-dom'
import { instagram, facebook, twitter, email } from '../../assets'

const Socials = () => (
    <section className="s-social">

        <div className="row social-content">
            <div className="column">
                <ul className="social-list">
                    <li className="social-list__item">
                        <NavLink to="https://www.facebook.com/cyfbeltsvillemd?mibextid=ZbWKwL" title=""
                            target="_blank"
                        >
                            <div className="social-list__icon social-list__icon--facebook" style={{ padding: "3px 0px" }}>
                                <img src={facebook} className="" alt='facebook' />
                            </div>
                            <span className="social-list__text">Facebook</span>
                        </NavLink>
                    </li>
                    <li className="social-list__item">
                        <NavLink to="/" title="">
                            <div className="social-list__icon social-list__icon--twitter" style={{ padding: "3px 0px" }}>
                                <img src={twitter} className="" alt='twitter' />
                            </div>
                            <span className="social-list__text">Twitter</span>
                        </NavLink>
                    </li>
                    <li className="social-list__item">
                        <NavLink to="/" title="">
                            <div className="social-list__icon social-list__icon--instagram" style={{ padding: "3px 0px" }}>
                                <img src={instagram} className="" alt='instagram' />
                            </div>
                            <span className="social-list__text">Instagram</span>
                        </NavLink>
                    </li>
                    <li className="social-list__item">
                        <NavLink to="/" title="">
                            <div className="social-list__icon social-list__icon--email" style={{ padding: "3px 0px" }}>
                                <img src={email} className="" alt='email' />
                            </div>
                            <span className="social-list__text">Email</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    </section>
)

export default Socials