import React from 'react'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { NavLink, Link } from 'react-router-dom';
import { convertTime, deletePost } from '../../utils/utils';


const EventCard = ({ item, id }) => {
  const { eventTitle, imageUrl, startDate, endDate, time, venue, timestamp, description } = item
  const user = JSON.parse(localStorage.getItem("user"))
  const { displayName } = user || {}
  const date = convertTime(timestamp)

  return (
    <div className=" events-list__item">
      <div className="gallery__item-imageContainer">
        <img src={imageUrl} alt="" />
      </div>

      <div className="event__infos">
        <h3 className="display-1 events-list__item-title">
          <NavLink to="events-single.html" title="">{eventTitle}</NavLink>
        </h3>
        <p>
          {description?.slice(0, 85)}...
        </p>
        <ul className="events-list__meta">
          <li className="events-list__meta-date"><h5>{startDate} - {endDate}</h5></li>
          <li className="events-list__meta-time">{time}</li>
          <li className="events-list__meta-location">{venue}</li>
          <li className="">
            <Link to={`/event/${id}`} className='gallery__item-link' state={item}>see more</Link>
          </li>

          <div className="gallery__item-info">
            Posted {date}
          </div>

        </ul>
        {
          displayName && (
            <div className="gallery__item-actions">
              <Link to={`/edit/${id}`} className='gallery__item-edit-link' state={item}>
                <EditIcon />
              </Link>
              <DeleteIcon onClick={() => deletePost(id)} />
            </div>
          )
        }

      </div>
    </div>
  )
}

export default EventCard