import React, { useState } from 'react'
import GalleryForm from './GalleryForm';
import EventForm from './EventForm';


const CreateEvent = () => {
  const [action, setAction] = useState("gallery")

  const handleInputChange = (e) => {
    setAction( e.target.value);
  };
  
    return (
      <>
        <section className='page-content'>
          <div className="main">
            <div className="main__header">
              <h2>{action === "event" ? "EVENTS" : "GALLERY"}</h2>
              <div className="main__input">
                <select name="action" value={action} onChange={handleInputChange}>
                  <option value="gallery">Save to Gallery</option>
                  <option value="event">Create Event</option>
                </select>
              </div>
            </div>
            {
             action === "event" ? (
              <EventForm/>
             ): (
              <GalleryForm/>
             ) 
            }
           
          </div>
        </section>
      </>
    )
  }

  export default CreateEvent