import { NavLink } from "react-router-dom"
import { EventCard, Pagination } from "../../components"



const Events = () => {
    const events = JSON.parse(localStorage.getItem("events"))

    return (
        <>
            <section className="page-header page-header--events">

                <div className="gradient-overlay"></div>
                <div className="row page-header__content">
                    <div className="column">
                        <h1>Upcoming Events</h1>
                    </div>
                </div>

            </section>


            <section className="page-content" style={{ paddingTop: "20px" }}>
                <div className="row wide block-large-1-2 block-900-full events-list">
                    {
                        events?.length === 0 && (
                            <div className="testimonies__fallback">
                                <h3>There are no events at the moment</h3>
                            </div>
                        )
                    }
                    {
                        events?.map(({ post, id }) => (
                            <EventCard item={post} id={id} key={id} />
                        ))
                    }
                </div>
                <Pagination events={events} />
            </section>


            <section className="s-social">

                <div className="row social-content">
                    <div className="column">
                        <ul className="social-list">
                            <li className="social-list__item">
                                <NavLink href="#0" title="">
                                    <span className="social-list__icon social-list__icon--facebook"></span>
                                    <span className="social-list__text">Facebook</span>
                                </NavLink>
                            </li>
                            <li className="social-list__item">
                                <NavLink href="#0" title="">
                                    <span className="social-list__icon social-list__icon--twitter"></span>
                                    <span className="social-list__text">Twitter</span>
                                </NavLink>
                            </li>
                            <li className="social-list__item">
                                <NavLink href="#0" title="">
                                    <span className="social-list__icon social-list__icon--instagram"></span>
                                    <span className="social-list__text">Instagram</span>
                                </NavLink>
                            </li>
                            <li className="social-list__item">
                                <NavLink href="#0" title="">
                                    <span className="social-list__icon social-list__icon--email"></span>
                                    <span className="social-list__text">Email</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Events
