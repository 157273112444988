import React from 'react'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from 'react-router-dom'
import { convertTime, deletePost } from '../../utils/utils';
import { Link, useParams } from 'react-router-dom';



const Event = () => {
  const { id } = useParams()
  const { state } = useLocation()
  const user = JSON.parse(localStorage.getItem("user"))
  const { displayName } = user || {}
  const { eventTitle, imageUrl, description, timestamp, startDate, endDate, time, venue } = state
  const date = convertTime(timestamp)


  return (
    <div>
      <section className="page-header">

        <div className="gradient-overlay"></div>
        <div className="row page-header__content">
          <div className="column">
            <h1>{eventTitle}</h1>
          </div>
        </div>

      </section>
      <section className='page-content'>
        <div className="gallery__items-info">
          <div className="main__image-container">
            <img src={imageUrl} alt="" />
          </div>
          <div className="" style={{ padding: "10px 25px" }}>
            <p>{description}</p>
            <ul className="events-list__meta" style={{ padding: "10px 25px" }}>
              <li className="events-list__meta-date"><h5>{startDate} - {endDate}</h5></li>
              <li className="events-list__meta-time">{time}</li>
              <li className="events-list__meta-location">{venue}</li>
              <div className="gallery__item-info">
                Posted {date}
              </div>
            </ul>
          </div>
        </div>

        {
          displayName && (
            <div className="gallery__item-actions">
              <Link to={`/edit/${id}`} className='gallery__item-edit-link' state={state}>
                <EditIcon />
              </Link>
              <DeleteIcon onClick={() => deletePost(id)} />
            </div>
          )
        }
      </section>
    </div>

  )
}

export default Event
