import { Socials } from '../../components'

const Contact = () => (
    <>
        <section className="page-header page-header--contact">

            <div className="gradient-overlay"></div>
            <div className="row page-header__content">
                <div className="column">
                    <h1>Contact Us</h1>
                </div>
            </div>

        </section>


        <section className="page-content">

            <div className="row">
                <div className="column">
                    <br />
                    <p className="lead drop-cap">
                        Staying connected to the church community is vital for several reasons:

                        Centering on God: It helps us focus our lives on God.
                        Fellowship: Connecting with other believers fosters relationships and support.
                        Spiritual Growth: Church involvement cultivates maturity in our faith.
                        Contributing: Being part of a community allows us to serve and contribute.
                        Witness: Our togetherness showcases God’s goodness to the world. 🙏✨
                    </p>

                    <p>
                        To contact us at PCC Beltsville, you can reach out via phone, email, or visit our website. Our friendly staff is ready to assist you with any inquiries, prayer requests, or information about our services and events. 🙏📞📧💻
                    </p>

                    <div className="row" style={{ margin: "30px 0" }}>
                        <div className="column large-6 tab-full" style={{ margin: "10px 0" }}>
                            <h3>Main Office.</h3>
                            <br />
                            <span>
                                Reverend Pastor
                            </span>
                            <br />
                            <br />
                            <span>
                                📞 +1 (614) 615-5346
                            </span>
                            <br />
                            <br />
                            <span>
                                Rev. Akih Mathias
                            </span>
                        </div>
                        <div className="column large-6 tab-full" style={{ margin: "10px 0" }}>
                            <h3>Main Office.</h3>
                            <br />
                            <span>
                                Chairman
                            </span>
                            <br />
                            <br />
                            <span>
                                📞 +1 (202) 790-7395
                            </span>
                            <br />
                            <br />
                            <span>
                                Elder Simon Pierre Teko
                            </span>
                        </div>

                        <div className="column large-6 tab-full" style={{ margin: "10px 0" }}>
                            <h3>Contact Info.</h3>
                            <br />
                            {/* <p>
                                sayhello@hesed.com<br />
                                info@hesed.com <br />
                                Phone: +197 543 2345
                            </p> */}
                            <p>
                                9830 Mallard Dr, MD 20708 <br />
                                Laurel Maryland USA
                            </p>
                        </div>
                    </div>


                    {/* <h2>Get In Touch.</h2>

                    <form name="contactForm" id="contactForm" method="post" action="" autocomplete="off">
                        <fieldset>

                            <div className="form-field">
                                <input name="cName" id="cName" className="h-full-width h-remove-bottom" placeholder="Your Name" value="" type="text" />
                            </div>

                            <div className="form-field">
                                <input name="cEmail" id="cEmail" className="h-full-width h-remove-bottom" placeholder="Your Email" value="" type="text" />
                            </div>

                            <div className="form-field">
                                <input name="cWebsite" id="cWebsite" className="h-full-width h-remove-bottom" placeholder="Website" value="" type="text" />
                            </div>

                            <div className="message form-field">
                                <textarea name="cMessage" id="cMessage" className="h-full-width h-remove-bottom" placeholder="Your Message"></textarea>
                            </div>

                            <br />

                            <input name="submit" id="submit" className="btn btn--primary btn-wide btn--large h-full-width" value="Send Message" type="submit" />

                        </fieldset>
                    </form> */}

                </div>
            </div>

        </section>
        <Socials />
    </>
)

export default Contact
