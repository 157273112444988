import { NavLink } from "react-router-dom";
import { logoNew } from "../../../assets";

export default function Footer() {
  const user = JSON.parse(localStorage.getItem("user"));
  const { displayName } = user || {};
  return (
    <footer className="s-footer">
      <div className="row footer-top">
        <div className="column large-4 medium-5 tab-full">
          <NavLink className="" to="/">
            <div className="footer__logo">
              <img src={logoNew} alt="Homepage" />
            </div>
          </NavLink>
          <br />
          <br />
          <p>
            God’s love is unconditional, extravagant, and all-encompassing.
            Ephesians 4:32 reminds us to live full of kindness and love, just as
            He loves us.
          </p>
        </div>
        <div className="column large-half tab-full">
          <div className="row">
            <div className="column large-7 medium-full">
              <h4 className="h6">Our Location</h4>
              <p>
                9830 Mallard Dr, MD 20708 <br />
                Laurel Maryland USA
              </p>
              <br />
              <p>
                <NavLink
                  to="https://maps.app.goo.gl/8RezkEpbc48DKXmNA"
                  className="btn btn--footer"
                >
                  Get Direction
                </NavLink>
              </p>
            </div>
            <div className="column large-5 medium-full">
              <h4 className="h6">Quick Links</h4>
              <ul className="footer-list">
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about">About</NavLink>
                </li>
                {displayName ? (
                  <li>
                    <NavLink to={`/profile/${displayName}`}>Profile</NavLink>
                  </li>
                ) : (
                  <li>
                    {" "}
                    <NavLink to="/login">Admin</NavLink>
                  </li>
                )}
                <li>
                  <NavLink to="/gallery">Gallery</NavLink>
                </li>
                <li>
                  <NavLink to="/testimonies">Testimonies</NavLink>
                </li>
                <li>
                  <NavLink to="/contact">Contact</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row footer-bottom">
        <div className="column ss-copyright">
          <span>&copy;Copyright PCC Belts Ville 2024</span>
          <span>
            {" "}
            <NavLink to="https://www.styleshout.com/"></NavLink>
          </span>
        </div>
      </div>
      <div className="ss-go-top">
        <NavLink className="smoothscroll" title="Back to Top" to="#top">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 0l8 9h-6v15h-4v-15h-6z" />
          </svg>
        </NavLink>
      </div>
    </footer>
  );
}
