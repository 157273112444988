import React, { useState } from 'react'
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { storage, db } from '../../firebaseConfig';
import './admin.css'
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, LinearProgress, CircularProgress } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
//  import { CloseTwoTone } from '@mui/icons-material';


const GalleryForm = () => {
  const navigate = useNavigate()
  const [urls, setUrls] = useState([])
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [previewImages, setPreviewImages] = useState([]);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("")
  const [formData, setFormData] = useState({
    eventTitle: "",
    action: "gallery"
  })
  const {
    eventTitle,
    action,
  } = formData

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };
  const removeImage = (index) => {
    const newPreviews = previewImages.filter((_, i) => i !== index);
    setPreviewImages(newPreviews);
  };
  const onFileChangeHandler = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files.length !== 0) {
        setPreviewImages((prev) => [...prev, URL.createObjectURL(e.target.files[i])]);
      }
      const newImage = e.target.files[i]
      newImage["id"] = Math.random();
      setImages((prev) => [...prev, newImage]);
    }

  };

  const handlePost = async (e) => {
    e.preventDefault()
    setLoading(true)
    const promises = []
    // eslint-disable-next-line
    images.map((file) => {
      if (!file) {
        setError("please upload a photo")
      } else {
        setError("")

        const storageRef = ref(storage, `galleryMedia/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        promises.push(uploadTask)
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(prog);
          },
          (err) => {
            const errorMessage = err.message;
            setError(errorMessage);
          },
          async () => {
            await getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
              setUrls(prevState => [...prevState, downloadURLs])

            })
    
          })
      }
      return null
    })
    await Promise.all(promises)
    .then(() => {
      setMessage("upload success")
    })
    if (message === "upload success") {
      addDoc(collection(db, `/posts`), {
        timestamp: serverTimestamp(),
        imageUrls: urls,
        eventTitle: eventTitle,
        action: action

      })

      setProgress(0);
      setPreviewImages(null);
      setImages(null);
      setLoading(false);
      navigate('/')
    }
  }



    return (
      <>
        <section className='page-content'>
          <div className="main">
            <div className="main__container">
              <div className="main__create">
                <form className="main__form" onSubmit={handlePost}>
                  <div>
                    <label className="login__input-upload">Upload Images (accepts multiple images):</label>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        multiple
                        onChange={onFileChangeHandler}
                      />
                      <CameraAltIcon size={20} color="inherit" />
                    </IconButton>
                  </div>
                  <div className="preview__container">
                    {previewImages?.map(
                      (preview, index) =>
                        preview && (
                          <div className="my-2">
                            <div className="preview__imageContainer">
                              <img
                                key={index}
                                className="previewImage"
                                src={preview}
                                alt=""
                                onClick={() => removeImage(index)}
                              />
                            </div>
                          </div>
                        )
                    )}
                  </div>
                  {previewImages?.map(
                    (image, index) =>
                      image && (
                        <Box sx={{ width: "100%", margin: "10px 0px" }}>
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                            key={index}
                          />
                        </Box>
                      )
                  )}
                  <div className="login__input">
                    <label>Title:</label>
                    <input placeholder=''
                      type="text" name="eventTitle" value={formData.eventTitle} onChange={handleInputChange}
                      required
                    />

                  </div>
                  <div className="login__button">
                    {
                      formData.eventTitle && (
                        <button type="submit" className={message === "upload success" ? "register__submit2" : "register__submit"}>
                          {message === "upload success" ? "FINISH" : "START SAVING"}
                        </button>
                      )
                    }
                  </div>
                  {
                    loading && (
                      <div className="loader">
                        <CircularProgress color="inherit" />
                      </div>
                    )
                  }
                  {
                    error && (
                      <span className="submit__error">{error}</span>
                    )
                  }
                </form>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

  export default GalleryForm