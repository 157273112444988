import React, { useState } from "react";

const Pagination = ({ events }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [numPerPage] = useState(10);
  // const indexOfLastRecord = currentPage * numPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - numPerPage;
  // const currentRecords = events?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(events?.length / numPerPage);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };

  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="row">
      <div className="column large-full">
        <nav className="pgn">
          <ul>
            <li
              className={currentPage === 1 ? "pgn__prev-disabled" : "pgn__prev"}
              onClick={goToPrevPage}
            ></li>
            {pageNumbers.map((pgNumber) =>
              currentPage === pgNumber ? (
                <li
                  className=""
                  key={pgNumber}
                  onClick={() => setCurrentPage(pgNumber)}
                >
                  <span className="pgn__num current">{pgNumber}</span>
                </li>
              ) : (
                <li
                  className=""
                  key={pgNumber}
                  onClick={() => setCurrentPage(pgNumber)}
                >
                  <span className="pgn__num">{pgNumber}</span>
                </li>
              )
            )}
            <li
              className={
                currentPage === nPages ? "pgn__prev-disabled" : "pgn__prev"
              }
              onClick={goToNextPage}
            ></li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Pagination;
