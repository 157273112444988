import React from 'react'
import { NavLink } from 'react-router-dom'

const About = () => {
    return (

        <>
            <section className="page-header page-header--about">


                <div className="row page-header__content">
                    <div className="column">
                        <h1>We Are PCC Beltsville</h1>
                    </div>
                </div>

            </section>
            <section className="page-content">

                <div className="row">
                    <div className="column">
                        <br />
                        <p className="lead drop-cap">
                            Knowing God gives peace,builds unity and inspires growth in value of knowing Christ the mentor.PCC Beltsville in its quest of guiding its Christians towards Christ ensures that the following aspects are matched with actions of faith and divine guidance throughout its institutions .
                        </p>

                        <div className="row">
                            <div className="column large-half medium-full">
                                <h2>Our Purpose.</h2>
                                <p>
                                    The mission of the Presbyterian Church in Beltsville like that of the universal church is to proclaim the Good news of God through Jesus Christ in word and action. The proclamation of the good news includes; preaching of the word, teaching, healing, and liberating the people of God from son (Lk 4: 18-19)

                                    The ultimate intention is to fulfill the mission of the Church with deep commitment to human dignity and basic human rights, and the moral and ethical obligations.
                                </p>
                            </div>

                            <div className="column large-half medium-full">
                                <h2>Our Mission.</h2>
                                <p>
                                    Preaching the Good News of God and matching it in words and action.Connecting those who lost faith with the help of our well structured institutions covering a vast arena of activities.Through our work with the help from partners we try to reach out even to those who seem disconnected from the word of God.Give your life a meaning through christ by reaching out in any way possible.
                                </p>
                            </div>
                        </div>
                        <br />
                        <blockquote cite="http://where-i-got-my-info-from.com">
                            <p>
                                For God so loved the world, that he gave his only Son, that whoever believes in
                                him should not perish but have eternal life. For God did not send his Son into
                                the world to condemn the world, but in order that the world might be
                                saved through him.
                            </p>
                            <cite>
                                <NavLink to="#0">John 3:16-17 ESV</NavLink>
                            </cite>
                        </blockquote>

                        <br />

                        <h2>Our Values.</h2>
                        <br />
                        <div className="row block-large-1-2 block-1000-full block-list">
                            <div className="column block-list__item">
                                <h6>Identity in Christ</h6>

                                <p>
                                    Our identity in Christ is a transformative theme emphasized in the New Testament. As believers, we’re new creations (2 Corinthians 5:17), chosen (Ephesians 1:5), and God’s masterpiece (Ephesians 2:10). We find strength, security, and purpose in our intimate union with Jesus. Remember, your worth comes from Him, not worldly standards. 🙏🏽
                                </p>
                            </div>

                            <div className="column block-list__item">
                                <h6>Church Is Family</h6>

                                <p>
                                    The church, like a close-knit family, shares bonds of love, support, and faith. We celebrate victories, comfort during trials, and grow together in our spiritual journey. Ephesians 2:19 beautifully describes us as “members of God’s household” 🙏🏽.
                                </p>
                            </div>

                            <div className="column block-list__item">
                                <h6>Kingdom Culture</h6>

                                <p>
                                    Kingdom culture in the church refers to a biblical framework influenced by apostolic leadership. Here are key aspects:

                                    Apostolic Influence: Kingdom culture churches are shaped by apostles, even if the leader isn’t one. Apostolic mission drives their purpose.
                                    Outward Focus: These churches prioritize reaching people or places, aiming for cultural and social impact.
                                    Experiential Worship: They cultivate personal encounters with God, relying on His power for mission success. 🙏🏽
                                </p>
                            </div>

                            <div className="column block-list__item">
                                <h6>Spirit Led Life</h6>

                                <p>
                                    A Spirit-led church manifests the fruit of the Spirit, including joy, and the gifts of the Spirit, such as prophecy and words from God. It crackles with energy and passion, focusing on the grace and glory of God revealed in Jesus Christ1. Additionally, key characteristics include fear of the Lord, desire for the Holy Spirit’s fullness, fervent preaching, and doctrinal teaching. 🙏🔥
                                </p>
                            </div>

                        </div>

                        <br />

                        <h2>What We Believe.</h2>
                        <br />
                        <div className="row block-large-1-2 block-1000-full block-list">
                            <div className="column block-list__item">
                                <h6>We Believe in Eternal Life</h6>
                                <p>
                                    Our belief in eternal life centers on the promise of everlasting existence. As Christians, we anticipate a future beyond this earthly realm. Through faith in Jesus Christ, we receive forgiveness and eternal communion with God. This hope sustains us, shaping our actions, priorities, and relationships. 🙏✨
                                </p>
                            </div>
                            <div className="column block-list__item">
                                <h6>
                                    We celebrate The Lord’s Supper on a table and not an altar
                                </h6>
                                <p>
                                    The Lord’s Supper, also known as Communion, is celebrated on a table rather than an altar because Jesus Himself instituted it during a common meal. In Luke 22:19, He instructed His disciples, “Do this in remembrance of me.” The focus is on simplicity: breaking bread and drinking wine in memory of Jesus, without elaborate rituals or altars. Let us continue to honor His command with reverence and gratitude. 🙏🍞🍷
                                </p>
                            </div>
                            <div className="column block-list__item">
                                <h6>
                                    Life after death
                                </h6>
                                <p>
                                    Our belief in life after death is rooted in faith and various religious traditions. Christians, for instance, anticipate eternal existence beyond this earthly life. Through Jesus Christ, we receive forgiveness and the promise of communion with God. This hope shapes our actions, priorities, and relationships, emphasizing the eternal over the temporal. 🙏✨
                                </p>
                            </div>
                            <div className="column block-list__item">
                                <h6>
                                    that sin is sin
                                </h6>
                                <p>
                                    Our belief in sin centers on recognizing human imperfection and separation from God. Sin is disobedience to God’s moral law, resulting in brokenness and estrangement. However, through Jesus Christ’s sacrifice, forgiveness and reconciliation are possible. Acknowledging our need for redemption, we seek God’s grace and transformation, striving to live in alignment with His will. 🙏❤️
                                </p>
                            </div>
                        </div>

                        <br />

                        <h2>Meet Our Team.</h2>

                        <div className="row block-large-1-2 block-tab-full church-staff">
                            <div className="column church-staff__item">
                                <div className="church-staff__header">
                                    <div className="church-staff__picture">
                                        <img src="images/avatars/user-01.jpg" alt="" />
                                    </div>
                                    <h4 className="church-staff__name">
                                        Jonathan Doe
                                        <span className="church-staff__position">
                                            Lead Pastor
                                        </span>
                                    </h4>
                                </div>

                                <p>
                                    a visionary shepherd who guides the church community. He inspires, teaches, and model Christ-like living, fostering spiritual growth and unity. 🙏🌟
                                </p>
                            </div>

                            <div className="column church-staff__item">
                                <div className="church-staff__header">
                                    <div className="church-staff__picture">
                                        <img src="images/avatars/user-02.jpg" alt="" />
                                    </div>
                                    <h4 className="church-staff__name">
                                        Jane Doe
                                        <span className="church-staff__position">
                                            Associate Pastor
                                        </span>
                                    </h4>
                                </div>

                                <p>
                                    Plays a vital role, assisting the Senior Pastor in preaching, teaching, pastoral care, and leadership development within our vibrant church community. 🙏🌟
                                </p>
                            </div>



                            <div className="column church-staff__item">
                                <div className="church-staff__header">
                                    <div className="church-staff__picture">
                                        <img src="images/avatars/user-05.jpg" alt="" />
                                    </div>
                                    <h4 className="church-staff__name">
                                        John Wesley
                                        <span className="church-staff__position">
                                            Youth Pastor
                                        </span>
                                    </h4>
                                </div>

                                <p>
                                    A man of character, shepherding Christ’s church through teaching and discipleship, specifically focusing on the youth and their families. 🙏🌟
                                </p>
                            </div>

                            <div className="column church-staff__item">
                                <div className="church-staff__header">
                                    <div className="church-staff__picture">
                                        <img src="images/avatars/user-06.jpg" alt="" />
                                    </div>
                                    <h4 className="church-staff__name">
                                        Aimee Semple McPherson
                                        <span className="church-staff__position">
                                            Kids Church Director
                                        </span>
                                    </h4>
                                </div>

                                <p>
                                    So passionate about nurturing children’s faith. They lead TWC Kids ministry, disciple young hearts, and empower families in their walk with Christ. 🙏🌟
                                </p>
                            </div>

                        </div>

                    </div>
                </div>

            </section>

        </>
    )
}

export default About
